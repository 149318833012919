import React, { useState, useEffect } from 'react';
import configData from '../config.json'
//import {withRouter} from 'react-router';
import { withRouter } from 'react-router-dom';
import { Currency } from '../components/Currency';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';

const TourInBarcaDaSorrento = (param) => {

    //const cache = useRef({});
    const [data, setData] = useState({});
    const [showSpan, setShowSpan] = useState(true);

    var header = { method: 'GET', headers: { 'Content-Type': 'application/json', 'Authorization': 'Basic YWRtaW46YWRtaW4=' } };

    async function fetchApi() {
        let response = await fetch((configData.server_url + param.url), header);
        let result = await response.json();
        if (result.code === 0) {
            document.title = `Category title: ${result.data.title}`;
            setData(result.data);
        }
    }

    useEffect(() => { window.scrollTo(0, 0); fetchApi() }, []);
    //used to currency component
    function onChange(newData) {
        setShowSpan(false);
        setData({ ...newData });
        //setCurrency(newCurrency);
    }

    return (

        <div>
            <Header />
            <div className="slider-int-cat">
                <img src="https://www.youknowboat.com/wp-content/themes/ykb2/images/tour-gruppo/sorrento-port.jpg" alt="escursioni in barca da sorrento" className="img-100" />
                <div className="container">
                    < div className="desc-cat">
                        <h1>{data.title}</h1>
                        {data.description}
                    </div>
                </div>
            </div>

            <div className="container">
                {data.title && <Currency data={data} onCurrencyChange={onChange} />}

                {data.post && data.post.map((postItem, i) => (
                    <div key={i} className="tours-flex-container">
                        <div className="flex-item img">
                            <div className="ribbon ribbon-giallo-bestseller">
                                <p>BEST SELLER</p>
                            </div>

                            <div className="img-blog pull-left">
                                <a href="#" title="<?php the_title_attribute() ?>" rel="bookmark">
                                    <img width="400" height="225" src={JSON.parse(postItem.document).copertina.path} />
                                </a>
                            </div>
                        </div>
                        <div className="flex-item testo">
                            <header>
                                <h2 className="category-title">
                                    <a itemProp="url" href="#" title="<?php the_title_attribute() ?>" rel="bookmark">
                                        <span itemProp="name">
                                            {postItem.title}
                                        </span>
                                    </a>
                                </h2>
                            </header>

                            <p>{postItem.description}</p>

                            <div className="durata">
                                <i className="fa fa-clock-o" aria-hidden="true"></i> Durata: 8 ore
                            </div>


                            <div className="lingua">
                                <ul>
                                    <li>Lingua:</li>
                                    <li><img src="https://www.youknowboat.com/wp-content/plugins/sitepress-multilingual-cms/res/flags/it.png" /></li>
                                    <li><img src="https://www.youknowboat.com/wp-content/plugins/sitepress-multilingual-cms/res/flags/en.png" /></li>
                                    <li><img src="https://www.youknowboat.com/wp-content/plugins/sitepress-multilingual-cms/res/flags/es.png" /></li>
                                </ul>
                            </div>

                            <div className="ico-group">
                                <i className="fa fa-user" aria-hidden="true"></i> Piccoli Gruppi
                            </div>

                        </div>
                        <div className="flex-item dettagli" itemProp="text">
                            <ul>
                                <li className="prezzo">da<br />
                                    <span>{showSpan && (<span style={{ display: 'inline' }}> € </span>)}{JSON.parse(postItem.document).prezzo_adulto}</span>
                                </li>
                            </ul>
                            <a href={postItem.slug} title="titolo" rel="bookmark" className="btn btn-lg btn-block btn-primary">Prenota adesso</a>
                            <div className="container-green-echo-aiuti">
                                <img src="https://www.youknowboat.com//wp-content/themes/ykb2/images/ico-foglia-greenecho.png" alt="foglia greenecho" />
                                <p className="green-echo-aiuti">con questo tour aiuti l’ambiente</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <Footer />
        </div>
    );
};

export default withRouter(TourInBarcaDaSorrento);

/*
<li>Field Della Categoria 'testo_post':  {data.document && data.document.testo_post}</li>
<li></li>
<li>Lista dei possibili field: {JSON.stringify(data.document)}</li>
</ul>


<ul>
                            <li>Name:               {postItem.name}</li>
                            <li>ISO:                {postItem.iso2}</li>
                            <li>Title:              {postItem.title}</li>
                            <li>Description:        {postItem.description}</li>
                            <li>Field 'testo_post': {JSON.parse(postItem.document).testo_post}</li>
                            <li>Field 'lingua_spagnola': {JSON.parse(postItem.document).lingua_spagnola}</li>
                            <li></li>
                            <li>Lista dei possibili field: {postItem.document}</li>
                        </ul>

                        <img width="400" height="225" src="https://www.youknowboat.com/wp-content/uploads/2019/02/Capri-prime-Copertina-400x225.jpg" data-src="https://www.youknowboat.com/wp-content/uploads/2019/02/Capri-prime-Copertina-400x225.jpg" className="center-block img-fluid wp-post-image lazyloaded" alt="" data-srcset="https://www.youknowboat.com/wp-content/uploads/2019/02/Capri-prime-Copertina-400x225.jpg 400w, https://www.youknowboat.com/wp-content/uploads/2019/02/Capri-prime-Copertina-300x169.jpg 300w, https://www.youknowboat.com/wp-content/uploads/2019/02/Capri-prime-Copertina-1024x576.jpg 1024w, https://www.youknowboat.com/wp-content/uploads/2019/02/Capri-prime-Copertina-768x432.jpg 768w, https://www.youknowboat.com/wp-content/uploads/2019/02/Capri-prime-Copertina-1536x864.jpg 1536w, https://www.youknowboat.com/wp-content/uploads/2019/02/Capri-prime-Copertina-2048x1152.jpg 2048w, https://www.youknowboat.com/wp-content/uploads/2019/02/Capri-prime-Copertina-900x506.jpg 900w" sizes="(max-width: 400px) 100vw, 400px" srcSet="https://www.youknowboat.com/wp-content/uploads/2019/02/Capri-prime-Copertina-400x225.jpg 400w, https://www.youknowboat.com/wp-content/uploads/2019/02/Capri-prime-Copertina-300x169.jpg 300w, https://www.youknowboat.com/wp-content/uploads/2019/02/Capri-prime-Copertina-1024x576.jpg 1024w, https://www.youknowboat.com/wp-content/uploads/2019/02/Capri-prime-Copertina-768x432.jpg 768w, https://www.youknowboat.com/wp-content/uploads/2019/02/Capri-prime-Copertina-1536x864.jpg 1536w, https://www.youknowboat.com/wp-content/uploads/2019/02/Capri-prime-Copertina-2048x1152.jpg 2048w, https://www.youknowboat.com/wp-content/uploads/2019/02/Capri-prime-Copertina-900x506.jpg 900w" />-->
*/
