import React, { useState, useEffect } from 'react';
import configData from '../config.json'
//import {withRouter} from 'react-router';
import { withRouter } from 'react-router-dom';
import { Header } from '../components/Header';

import { Footer } from '../components/Footer';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Currency } from '../components/Currency';


const Tour = (param) => {
    const [dataBoot, setDataBoot] = useState({}); //use to currency component
    const [data, setData] = useState({});
    const [showSpan, setShowSpan] = useState(true);

    var header = { method: 'GET', headers: { 'Content-Type': 'application/json', 'Authorization': 'Basic YWRtaW46YWRtaW4=' } };

    async function fetchApi() {
        let response = await fetch((configData.server_url + param.url), header);
        let result = await response.json();
        if (result.code === 0) {
            document.title = `Tour title: ${result.data.title}`;
            setDataBoot(result.data);
            setData(result.data);
            console.log(result.data);
        }
    }

    useEffect(() => { fetchApi() }, []);

    //used to currency component
    function onChange(newData) {
        setShowSpan(false);
        setData({ ...newData });
        //setCurrency(newCurrency);
    }


    return (
        <div>
            <Header />

            <img width="1900" height="768" src={data.document && data.document.copertina.path} className="img-fluid" />

            <div class="container">
                {data.title && <Currency data={data} onCurrencyChange={onChange} />}
                <div class="row">
                    <div class="col-md-8 ">
                        <section class="breadcrambs">
                            <ul>
                                <li><a href="https://www.youknowboat.com"><i class="fa fa-home" aria-hidden="true"></i> Home</a> &gt;</li>
                                <li>
                                    <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-da-sorrento/" title="Escursioni di gruppo in barca da Sorrento">
                                        Escursioni di gruppo in barca da Sorrento
                                    </a> &gt;
                                </li>
                                <li>
                                    <a href="https://www.youknowboat.com/escursione-barca-sorrento-capri-grotta-azzurra/" title="Escursione in barca a Sorrento, Capri e Grotta Azzurra – Esperienza Prime con massimo 8 passeggeri">
                                        Escursione in barca a Sorrento, Capri e Grotta Azzurra – Esperienza Prime con massimo 8 passeggeri</a>
                                </li>
                            </ul>
                        </section>
                        <article>
                            <header class="page-header">
                                <h1 className="title-post">Tour {data.title}</h1>
                                <div class="organizzato_da">
                                    Organizzato da
                                    <a class="btn btn-tooltip" data-toggle="tooltip" data-html="true" title="" data-original-title="You Know! srl unipersonale<br />P.IVA: 06847261218">
                                        You Know!
                                    </a>
                                </div>
                            </header>
                            <p>{data.document && data.document.testo_anteprima}</p>
                            <div class="gallery">
                                
                                <img width="1900" height="768" src={data.document && data.document.gallery1.path} className="img-fluid" />

                                
                            </div>
                            <div class="high">
                                <div class="high-item">
                                    <FontAwesomeIcon icon={["fas", "hourglass-start"]} /><br />

                                    <strong>Durata</strong><br />
                                    8 ore </div>
                                <div class="high-item">
                                    <FontAwesomeIcon icon={["fas", "calendar"]} /><br />
                                    <strong>Disponibile</strong><br />Tutti i giorni
                                </div>
                                <div class="high-item">
                                    <FontAwesomeIcon icon={["fas", "user"]} /><br />
                                    <strong>Piccolo Gruppo</strong><br />Max 8 persone</div>
                                <div class="high-item">

                                    <FontAwesomeIcon icon={["fas", "map-marker"]} /><br />
                                    <strong>Pick-up</strong><br />Hotel a Sorrento </div>
                                <div class="high-item">
                                    <FontAwesomeIcon icon={["fas", "clock"]} /><br />
                                    <strong>Partenza</strong><br />
                                    08:30 </div>
                            </div>
                            <section class="margin-bottom-25">


                                {data.document && <div dangerouslySetInnerHTML={{ __html: data.document.corpo }} />}
                            </section>
                        </article>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="promo sticky-top">
                            <div class="container-prezzo ">
                                <div class="prezzo-adulto ">


                                    <p class="prezzo">{showSpan && (<span style={{ display: 'inline' }}> € </span>)} {data.document && data.document.prezzo_adulto} <span className="label-adulti"> Adulti</span></p>
                                </div>
                            </div>
                            <div class="cancellazione-info">
                                <span>Cancellazione gratuita entro 24 ore</span>
                            </div>
                            <div class="extra-info">
                                <div class="durata">
                                    <i class="fa fa-clock-o" aria-hidden="true"></i> Durata: 8 ore </div>
                                <div class="lingua">
                                    <ul>
                                        <li>Lingua:</li>
                                        <li><img src="https://www.youknowboat.com/wp-content/plugins/sitepress-multilingual-cms/res/flags/it.png" /></li>
                                        <li><img src="https://www.youknowboat.com/wp-content/plugins/sitepress-multilingual-cms/res/flags/en.png" /></li>
                                        <li><img src="https://www.youknowboat.com/wp-content/plugins/sitepress-multilingual-cms/res/flags/es.png" /></li>
                                    </ul>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                            <div class="container-calendar">
                                <script src="https://fareharbor.com/embeds/script/calendar/youknowboat/items/134916/?fallback=simple&amp;flow=no"></script><div class="fareharbor-calendar-wrap"><iframe id="1635235920717-16540475201858706" name="fareharbor-embed:%7B%22isLightframed%22%3Atrue%2C%22parentUrl%22%3A%22https%3A%2F%2Fwww.youknowboat.com%2Fescursione-barca-sorrento-capri-grotta-azzurra%2F%22%2C%22embedId%22%3A%221635235920717-16540475201858706%22%7D" frameBorder="0" border="0" width="100%" src="https://fareharbor.com/embeds/calendar/youknowboat/items/134916/?flow=no&amp;u=cd0a40ec-ae18-49e2-959a-e0ed65abd480&amp;from-ssl=yes&amp;ga=UA-50354260-1,666665562.1627712457%3B&amp;back=https://www.youknowboat.com/escursione-barca-sorrento-capri-grotta-azzurra/" title="FareHarbor" ></iframe></div> </div>
                            <div class="clearfix"></div>
                            <div class="incluso">
                                <p>In Evidenza:</p>
                                <ul>
                                    <li><i class="fa fa-check" aria-hidden="true"></i>Garantito tutti i giorni</li>
                                    <li><i class="fa fa-check" aria-hidden="true"></i>Piccoli gruppi (max. 8 persone)</li>
                                    <li><i class="fa fa-check" aria-hidden="true"></i>Trasferimento da/per hotel</li>
                                    <li><i class="fa fa-check" aria-hidden="true"></i>Skipper professionale e assistente a bordo</li>
                                    <li><i class="fa fa-check" aria-hidden="true"></i>Stuzzichini e bevande incluse</li>
                                    <li><i class="fa fa-check" aria-hidden="true"></i>Entrata garantita alla Grotta Azzurra</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />

        </div>
    );
};

// <img width="400" height="225" src={JSON.parse(postItem.document).copertina.path} />
export default withRouter(Tour);