import React from 'react';
import configData from '../config.json'
export class Footer extends React.Component {
    render() {
        return (
            <div>
                <div class="container-nsl-home">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6 offset-md-3">
                                <i class="fas fa-envelope"></i>
                                <h4>Iscriviti alla Newsletter!</h4>
                                <p>Per ricevere sconti esclusivi e le trend news di You Know Life su Viaggi, Made in Italy e Green Economy direttamente nella tua casella di posta.</p>
                                <button class="btn btn-warning btn-nsl">Iscriviti ora!</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-promesse">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12  col-sm-12 col-12 titolo">
                                <p>Le città di partenza</p>
                                <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-da-sorrento" class="btn btn-light">Tour in barca da Sorrento</a>
                                <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-da-positano" class="btn btn-light">Tour in barca da Positano</a>
                                <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-da-amalfi" class="btn btn-light">Tour in barca da Amalfi</a>
                                <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-da-capri" class="btn btn-light">Tour in barca da Capri</a>
                                <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-da-napoli" class="btn btn-light">Tour in barca da Napoli</a>
                                <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-da-roma" class="btn btn-light">Tour in barca da Roma</a>
                                <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-da-salerno" class="btn btn-light">Tour in barca da Salerno</a>
                                <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-da-venezia" class="btn btn-light">Tour in barca a Venezia</a>
                                <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-da-porto-cesareo" class="btn btn-light">Tour in barca da Porto Cesareo</a>
                                <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-da-polignano-a-mare" class="btn btn-light">Tour in barca da Polignano a Mare</a>
                                <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-da-cefalu" class="btn btn-light">Tour in barca da Cefalù</a>
                                <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-alle-isole-egadi" class="btn btn-light">Tour in barca alle Isole Egadi</a>
                                <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-alle-cinque-terre" class="btn btn-light">Tour in barca alle Cinque Terre</a>
                                <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-a-santorini" class="btn btn-light">Tour in barca a Santorini</a>
                                <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-da-naxos" class="btn btn-light">Tour in barca da Naxos</a>
                                <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-da-creta" class="btn btn-light">Tour in barca da Creta</a>
                                <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-da-dubrovnik" class="btn btn-light">Tour in barca da Dubrovnik</a>
                                <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-da-spalato" class="btn btn-light">Tour in barca da Spalato</a>
                                <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-da-zara" class="btn btn-light">Tour in barca da Zara</a>
                                <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-da-trogir" class="btn btn-light">Tour in barca da Trogir</a>
                                <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-da-hvar" class="btn btn-light">Tour in barca da Hvar</a>
                                <br /><br />
                                <p>Le 10 città più richieste</p>
                                <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-da-sorrento" class="btn btn-light">Sorrento</a>
                                <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-da-napoli" class="btn btn-light">Napoli</a>
                                <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-da-roma" class="btn btn-light">Roma</a>
                                <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-da-capri" class="btn btn-light">Capri</a>
                                <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-da-polignano-a-mare" class="btn btn-light">Polignano a Mare</a>
                                <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-da-venezia" class="btn btn-light">Venezia</a>
                                <a href="https://www.youknowboat.com/escursione-di-gruppo-in-barca-alle-cinque-terre" class="btn btn-light">Cinque Terre</a>
                                <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-alle-isole-egadi" class="btn btn-light">Isole Egadi</a>
                                <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-a-santorini" class="btn btn-light">Santorini</a>
                                <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-da-naxos" class="btn btn-light">Naxos</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid footer">
                    <div class="container">
                        <div class="row ">
                            <div class="col-md-12 col-sm-12 col-12 footer-2">
                                <div class="item-logo">
                                    <img class="img-fluid" src="https://www.youknowboat.com/wp-content/themes/ykb2/images/ykb-logo-footer.png" />
                                </div>
                                <div class="item-azienda">
                                    <h3>LA NOSTRA AZIENDA</h3>
                                    <div class="menu-azienda-footer-container"><ul id="azienda-footer" class="menu"><li id="menu-item-30078" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-30078 menu-item-chi-siamo first-menu-item"><a href="https://www.youknowboat.com/chi-siamo/">&gt; Chi siamo</a></li>
                                        <li id="menu-item-29885" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-29885 menu-item-inside-ykb "><a href="https://www.youknowboat.com/inside-ykb/">&gt; Inside YKB</a></li>
                                        <li id="menu-item-29886" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-29886 menu-item-green-echo "><a href="https://www.youknowboat.com/green-echo/">&gt; Green Echo</a></li>
                                        <li id="menu-item-35432" class="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-35432 menu-item-gozzo-jeranto-750-classic last-menu-item"><a href="https://www.youknowboat.com/blog/">&gt; Blog</a></li>
                                    </ul></div> </div>
                                <div class="item-aiuto">
                                    <h3>HAI BISOGNO DI AIUTO?</h3>
                                    <div class="menu-aiuto-footer-container"><ul id="aiuto-footer" class="menu"><li id="menu-item-29888" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-29888 menu-item-contatti first-menu-item"><a href="https://www.youknowboat.com/contatti/">&gt; Contatti</a></li>
                                        <li id="menu-item-29901" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-29901 menu-item-domande-frequenti "><a href="https://www.youknowboat.com/domande-frequenti/">&gt; FAQ</a></li>
                                        <li id="menu-item-31482" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-31482 menu-item-privacy "><a href="https://www.youknowboat.com/privacy/">&gt; Privacy &amp; Cookies Policy</a></li>
                                        <li id="menu-item-29902" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-29902 menu-item-informazioni-legali "><a href="https://www.youknowboat.com/informazioni-legali/">&gt; Informazioni legali</a></li>
                                        <li id="menu-item-29887" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-29887 menu-item-condizioni "><a href="https://www.youknowboat.com/condizioni/">&gt; Condizioni d’uso</a></li>
                                        <li id="menu-item-32848" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-32848 menu-item-trasparenza "><a href="https://www.youknowboat.com/trasparenza/">&gt; Trasparenza</a></li>
                                        <li id="menu-item-29900" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-29900 menu-item-covid-19-e-politica-di-cancellazione last-menu-item"><a href="https://www.youknowboat.com/covid-19-e-politica-di-cancellazione/">&gt; COVID-19</a></li>
                                    </ul></div> </div>
                                <div class="item-pagamenti">
                                    <h3>PAGAMENTI SICURI</h3>
                                    <ul>
                                        <li>Pagamenti sicuri e criptati </li>
                                        <li>Conferma immediata</li>
                                        <li>Servizio clienti 24/7</li>
                                    </ul>
                                    <img src="https://www.youknowboat.com/wp-content/themes/ykb2/images/credit-card.png" srcset="https://www.youknowboat.com/wp-content/themes/ykb2/images/credit-card.png 2x" class="cc-footer" alt="Logo Etoa 2019" />
                                </div>
                                <div class="item-loghi">
                                    <a href="https://www.tripadvisor.it/Attraction_Review-g616172-d2536656-Reviews-or10-You_Know_Boat_Excursions_Service-Piano_di_Sorrento_Province_of_Naples_Campan.html" target="_blank"><img src="https://www.youknowboat.com/wp-content/themes/ykb2/images/loghi/logo_tripadvisor2020.png" width="75" height="66" srcset="https://www.youknowboat.com/wp-content/themes/ykb2/images/loghi/logo_tripadvisor2020.png 2x" class="trip-loghi" alt="Logo Tripadvisor Certificato 2018" /></a>
                                    <img src="https://www.youknowboat.com/wp-content/themes/ykb2/images/etoa.png" width="70" height="37" srcset="https://www.youknowboat.com/wp-content/themes/ykb2/images/etoa-x2.png 2x" class="etoa-loghi" alt="Logo Etoa 2020" />
                                    <a href="https://www.youknowboat.com/wp-content/themes/ykb2/download/ykb-plastic-certificate.pdf" target="_blank"><img src="https://www.youknowboat.com/wp-content/themes/ykb2/images/loghi/logo_plasticfree.png" srcset="https://www.youknowboat.com/wp-content/themes/ykb2/images/loghi/logo_plasticfree.png 2x" class="plastic-loghi" alt="Plastic Free" /></a><br />
                                    <img src="https://www.youknowboat.com/wp-content/themes/ykb2/images/loghi/logo_confindustria.png" width="265" height="69" srcset="https://www.youknowboat.com/wp-content/themes/ykb2/images/loghi/logo_confindustria.png 2x" class="confindustria-loghi" alt="Plastic Free" />
                                    <a href="https://www.youknowboat.com/wp-content/themes/ykb2/download/iso9001.pdf" target="_blank"><img src="https://www.youknowboat.com/wp-content/themes/ykb2/images/loghi/iso9001.png" width="87" height="54" srcset="https://www.youknowboat.com/wp-content/themes/ykb2/images/loghi/iso9001.png 2x" class="iso iso9001" alt="Plastic Free" /></a>
                                    <a href="https://www.youknowboat.com/wp-content/themes/ykb2/download/iso14001.pdf" target="_blank">&gt;<img src="https://www.youknowboat.com/wp-content/themes/ykb2/images/loghi/iso14001.png" width="87" height="54" srcset="https://www.youknowboat.com/wp-content/themes/ykb2/images/loghi/iso14001.png 2x" class="iso iso14001" alt="Plastic Free" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-fluid dati-aziendali">
<div class="row">
<div class="container">
<div class="row ">
<div class="col-12">
You Know! Srl Unipersonale - P.IVA 06847261218 - Capitale Sociale: 100.000,00 I.V.<br />
Copyright © 2011–2021
</div>
</div>
</div>
</div>
</div>

<div class="firma-merisia">
<a href="https://www.merisia.it" target="_blank"><img src="https://www.youknowboat.com/wp-content/themes/ykb2/images/merisia.png" srcset="https://www.youknowboat.com/wp-content/themes/ykb2/images/merisia-x2.png 2x" class="" alt="Logo Merisia Web Sorrento" /></a>
</div>


            </div>
        )
    }
}

export default Footer;
