import React from 'react';
import configData from '../config.json'
export class Header extends React.Component {
	render() {
		return (
			<header>
				<div className="topbar">
					<div className="flex-header">
						<div className="row-1">
							<div className="container">
								<div className="row">

									<div className="container-logo">
										<a href="/" title="You Know" rel="home">
											<img src={`${configData.base_url}/images/ykb-logo.png`} />
										</a>
									</div>

									<div className="bottone-menu">
										<i className="fa fa-bars" aria-hidden="true"></i>
										<span>MENU</span>
									</div>

									<div className="menu-top">
										<img className="img-fluid logo-menu" src={`${configData.base_url}/images/ykb-logo.png`} />
										<div className="menu-mainmenu-container">
											<ul>
												<li><a href="#">Escursioni di gruppo in barca</a></li>
												<li><a href="#">Tour privati in barca</a></li>
												<li><a href="#">Gift Card</a></li>
												<li><a href="#">Contatti</a></li>
												<li><a href="#">FAQ</a></li>
												<li><a href="#">Blog</a></li>
											</ul>
										</div>
										<div className="mask-menu"></div>
									</div>

									<div className="lingua-menu">
										<div className="header-area">
											<span></span>
										</div>
									</div>
								</div>
							</div>

							<div className="row-2">
								<div className="sottomenu">
									<div className="container">
										<ul>
											<li><a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-in-italia">Italia</a></li>
											<li><a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-in-grecia/">Grecia</a></li>
											<li><a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-in-croazia/">Croazia</a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>

		);
	}
}

export default Header;


