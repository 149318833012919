import React, { useState, useEffect } from 'react';
import configData from '../config.json'
//import {withRouter} from 'react-router';
import { withRouter } from 'react-router-dom';

import  { Currency }  from '../components/Currency';
//import CategoryItem from './CategoryItem';
//import { createUnparsedSourceFile } from 'typescript';

//import Posts from './Posts';

const Category = (param) => {
  
  const [dataBoot, setDataBoot] = useState({}); //use to currency component
  const [data, setData] = useState({});
  
  var header = { method: 'GET', headers: {'Content-Type': 'application/json', 'Authorization': 'Basic YWRtaW46YWRtaW4='}};

  async function fetchApi() {
    let response = await fetch((configData.server_url + param.url), header);
    let result = await response.json();
    if(result.code === 0){
      document.title = `Category title: ${result.data.title}`;
      setDataBoot(result.data);
      setData(result.data); 
    }
  }

  useEffect(() => { fetchApi() },[]);

  //used to currency component
  function onChange(newData) {   
    setData({...newData});
    //setCurrency(newCurrency);
  }

  return (
    <div>
      <h1>Category {data.title}</h1>
      <br />
      <ul>
        <li>ISO:          {data.iso2}</li>
        <li>Title:        {data.title}</li>
        <li>Description:  {data.description}</li>
        <li>Lista Posts:  {data.post && data.post.map((postItem, i) => (
          <div key={i}>
              <ul>
                  <li>Name:         {postItem.name}</li>
                  <li>ISO:          {postItem.iso2}</li>
                  <li>Title:        {postItem.title}</li>
                  <li>Description:  {postItem.description}</li>
                  <li>Document:     {postItem.document}</li>
              </ul>
              <span>----------------------------</span>
          </div> 
        ))}</li>
      </ul>
      { data.title && <Currency data = { data } dataBoot = { dataBoot } onCurrencyChange={onChange}/> } 
    </div>
  );
}; 

/*

<li>Lista Posts:  {postItem.document && postItem.document.map((docItem, i) => (
                    <div key={i}>
                      <li>Document:         {docItem}</li>
                    </div>
                  ))}</li> 

*/

// <CategoryItem item={data}/>
//     <div>{ cur }</div>

export default withRouter(Category);