import React, { useState, useEffect } from 'react';
import configData from '../config.json'
import { Switch, Route, withRouter } from 'react-router-dom';

import App from '../App';
import Wine from '../layout_category/Wine';
import Category from '../layout_category/Category';
import TourInBarcaDaSorrento from '../layout_category/TourInBarcaDaSorrento';
import { ExitStatus } from 'typescript';
import Tour from '../layout_category/Tour';

const Routes = () => {

  const [data, setPost] = useState(null);

  var header = { method: 'get', headers: { 'Content-Type': 'application/json', 'Authorization': 'Basic YWRtaW46YWRtaW4=' } };

  // first of all: get all the posts:
  async function fetchApi() {

    let response = await fetch(
      configData.server_url + '/route', header
    );

    let result = await response.json();
    console.log(result.data);
    setPost(result.data);
  }


  useEffect(() => { fetchApi() }, []);


  return (
    <Switch>
      <Route exact path="/" render={withRouter((props) => <App {...props} />)} />
      <Route path="/vino" render={withRouter((props) => <Wine {...props} />)} />
      <Route path="/en/wine" render={withRouter((props) => <Wine {...props} />)} />
      {data && data.map((route, i) => (
        <Route key={i} path={route.path} render={withRouter(() => <GetRoute name={route.layout} url={route.url} />)} />
      ))}
    </Switch>
  );

}

function GetRoute(input) {
  console.log(input);
  // indica la categoria, post o pagina dove nel primo elemento c'è il nome dell'oggetto mentre il secondo parametro è il componente da usare.
  const componentsMap = {
    Category: Category,
    Tourinbarcadasorrento: TourInBarcaDaSorrento,
    Escursioneinbarcaibridapositano: TourInBarcaDaSorrento,
    Tour: Tour

  };
  const Component = componentsMap[input.name];

  if (!Component) {
    return <p>Not Found</p>;
  }
  return <Component url={input.url} />;
}

export default Routes;