import React, { useState, useEffect } from 'react';
import configData from '../config.json'
//import {withRouter} from 'react-router';
import { withRouter } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import "bootstrap-icons/font/bootstrap-icons.css";
import { Header } from '../components/Header';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Glide } from 'react-glide';
//import 'react-glide/lib/reactGlide.css';


const Wine = (param) => {

  //const imageName = 'article2Image.png';
  //const cache = useRef({});
  const [data, setPost] = useState({});


  async function fetchApi() {
    let response = await fetch(
      configData.server_url + param.url,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Basic YWRtaW46YWRtaW4='
        }
      }
    );
    let result = await response.json();
    setPost(result.data);
  }
  useEffect(() => {
    fetchApi()
  });
  return (

    <div>
      <h1>Category Vino</h1>
      {data.title}
      <Header />

      <i class="bi-alarm"></i>

      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://www.youknowboat.com/wp-content/themes/ykb2/images/slide/boat-tour-grecia.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <p>Santorini - Grecia</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://www.youknowboat.com/wp-content/themes/ykb2/images/slide/boat-tour-capri.jpg"
            alt="Second slide"
          />

          <Carousel.Caption>
            <p>Capri - Italia</p>
          </Carousel.Caption>
        </Carousel.Item>

      </Carousel>

      <div class="container-covid">
        <div class="container">
          <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />

          <p>Covid-19: Ci teniamo alla salute e alla sicurezza dei nostri viaggiatori. Per questo, continuiamo a monitorare la situazione e restiamo al tuo fianco per aiutarti con qualsiasi domanda o dubbio sui tuoi prossimi piani di viaggio. Contattaci a info@youknowboat.com​ oppure leggi di più <a href="<?php echo get_home_url(); ?>/covid-19-e-politica-di-cancellazione">cliccando ​qui​</a>.
          </p>
        </div>
      </div>


      <div class="container-fluid container-motivi-home">
        <div class="row">
          <div class="container">
            <div class="motivi-home">
              <div class="motivi-home-item">
                <FontAwesomeIcon icon={["fas", "water"]} />
                <span>Oltre 150 esperienze</span>
              </div>
              <div class="motivi-home-item">
                <FontAwesomeIcon icon={["fas", "star"]} />
                <span>Migliaia di recensioni</span>
              </div>
              <div class="motivi-home-item">
                <FontAwesomeIcon icon={["fas", "hand-holding-usd"]} />
                <span>Miglior prezzo</span>
              </div>
              <div class="motivi-home-item">
                <FontAwesomeIcon icon={["fas", "credit-card"]} />
                <span>Prenotazioni veloci</span>
              </div>
              <div class="motivi-home-item">
                <FontAwesomeIcon icon={["fas", "life"]} />
                <span>Assistenza 24/7</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h1>Category Vino</h1>
      {data.title}
      <FontAwesomeIcon icon="coffee" />
      <i class="bi-alarm"></i>

      <Glide>
        <img src='https://www.youknowboat.com/wp-content/themes/ykb2/images/slide/boat-tour-capri.jpg' />
        <img src='https://www.youknowboat.com/wp-content/themes/ykb2/images/slide/boat-tour-grecia.jpg' />
      </Glide>

    </div> // chiudi container 
  );
};

export default withRouter(Wine);
//export default Wine;

