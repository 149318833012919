import React from 'react';
import Form from 'react-bootstrap/Form'
export class Currency extends React.Component {

    constructor(props) {
        super(props);
        this.state = { type: null, data: this.props.data};
        //this.state = { type: null, cur: this.props.cur };
    }
    
    render() {
        return (
            <Form>
            <Form.Group className="mb-3" controlId="formCurrency">
                <Form.Control as="select" value={this.type}
                    onChange={e => {

                        let selectValue = e.target.value.split(',');

                        //console.log( this.state.data.document); return false;
                        
                        if(this.state.data.post){
                            this.state.data.post.map((item,key) => {
                                let val;
                                let doc = JSON.parse(item.document);
                                let price = doc.prezzo_adulto.split(' ');

                                console.log(price[1]);

                                if(price[1]){
                                    console.log('ok');
                                    val = doc.prezzo_adulto.split(' ')[1];
                                }
                                else{
                                    console.log('ko');
                                    val = doc.prezzo_adulto;
                                }
                                
    
                                if(selectValue[1] == 'usd'){
                                    doc.prezzo_adulto = '$ ' + (parseFloat(val).toFixed(2) * parseFloat(selectValue[0]).toFixed(2)).toFixed(2);
                                }
                                else{
                                    doc.prezzo_adulto = '€ ' + (parseFloat(val).toFixed(2) / parseFloat(selectValue[0]).toFixed(2)).toFixed(2);
                                }        
                                item.document = JSON.stringify(doc);
                            })
                        }
                        else{
                           
                                let val;
                           
                                let price = this.state.data.document.prezzo_adulto.split(' ');

                                console.log(price[1]);

                                if(price[1]){
                                    console.log('ok');
                                    val = this.state.data.document.prezzo_adulto.split(' ')[1];
                                }
                                else{
                                    console.log('ko');
                                    val = this.state.data.document.prezzo_adulto;
                                }
                                
    
                                if(selectValue[1] == 'usd'){
                                    this.state.data.document.prezzo_adulto = '$ ' + (parseFloat(val).toFixed(2) * parseFloat(selectValue[0]).toFixed(2)).toFixed(2);
                                }
                                else{
                                    this.state.data.document.prezzo_adulto = '€ ' + (parseFloat(val).toFixed(2) / parseFloat(selectValue[0]).toFixed(2)).toFixed(2);
                                }        
                                //this.state.data.document = JSON.stringify(doc);
                            
                        }

                        this.props.onCurrencyChange(this.state.data);
                        //this.props.onCurrencyChange(parseInt(this.state.cur) + parseInt(e.target.value))
                    }}
                >
                    <option value="1.16,eur">EUR</option>
                    <option value="1.16,usd">USD</option>
                </Form.Control>
            </Form.Group>
            </Form>  
        ); 
    }
}

/*

                 <option value="">EUR</option>
                    <option value="1.16">USD</option>
                    <option value="0.85">GPB</option>
                    <option value="81.93">RUB</option>
                    <option value="132.16">JPY</option>
*/

export default Currency;


