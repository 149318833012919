import React, { useState, useEffect, Fragment, useRef } from 'react';
import configData from './config.json'
import { withRouter, useHistory } from 'react-router-dom';

//import AOS from 'aos';
//import 'aos/dist/aos.css';
//import { withRouter } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import "bootstrap-icons/font/bootstrap-icons.css";
import { Header } from './components/Header';
import { Footer } from './components/Footer';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Glide, { Slide } from 'react-glidejs';

import 'react-glidejs/dist/index.css';
//import './transitions.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';

import { faCheckSquare, faCoffee, faExclamationTriangle, faCreditCard, faWater, faStar, faHandHoldingUsd, faLifeRing, faCalendarTimes, faHourglassStart, faUser, faCalendar, faMapMarker, faClock } from '@fortawesome/free-solid-svg-icons';

library.add(fab, faCheckSquare, faCoffee, faExclamationTriangle, faCreditCard, faWater, faStar, faHandHoldingUsd, faLifeRing, faCalendarTimes, faHourglassStart, faUser, faCalendar, faMapMarker, faClock)

function App() {
  const [data, setPost] = useState({});
  const history = useHistory();

  async function fetchApi() {
    let response = await fetch(
      configData.server_url,// +param.url,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Basic YWRtaW46YWRtaW4='
        }
      }
    );
    let result = await response.json();
    setPost(result.data);
  }

  useEffect(() => {
    // fetchApi() 
  });

  const gliderRef = useRef(null);
  const gliderRef1 = useRef(null);
  return (
    <div className="App">
      <Header />


      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://www.youknowboat.com/wp-content/themes/ykb2/images/slide/boat-tour-grecia.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <p>Santorini - Grecia</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://www.youknowboat.com/wp-content/themes/ykb2/images/slide/boat-tour-capri.jpg"
            alt="Second slide"
          />

          <Carousel.Caption>
            <p>Capri - Italia</p>
          </Carousel.Caption>
        </Carousel.Item>

      </Carousel>

      <div className="container-covid">
        <div className="container">
          <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />

          <p>Covid-19: Ci teniamo alla salute e alla sicurezza dei nostri viaggiatori. Per questo, continuiamo a monitorare la situazione e restiamo al tuo fianco per aiutarti con qualsiasi domanda o dubbio sui tuoi prossimi piani di viaggio. Contattaci a info@youknowboat.com​ oppure leggi di più <a href="<?php echo get_home_url(); ?>/covid-19-e-politica-di-cancellazione">cliccando ​qui​</a>.
          </p>
        </div>
      </div>


      <div className="container-motivi-home">
        <div className="container">
          <div className="motivi-home">
            <div className="motivi-home-item">
              <FontAwesomeIcon icon={["fas", "water"]} />
              <span>Oltre 150 esperienze</span>
            </div>
            <div className="motivi-home-item">
              <FontAwesomeIcon icon={["fas", "star"]} />
              <span>Migliaia di recensioni</span>
            </div>
            <div className="motivi-home-item">
              <FontAwesomeIcon icon={["fas", "hand-holding-usd"]} />
              <span>Miglior prezzo</span>
            </div>
            <div className="motivi-home-item">
              <FontAwesomeIcon icon={["fas", "credit-card"]} />
              <span>Prenotazioni veloci</span>
            </div>
            <div className="motivi-home-item">
              <FontAwesomeIcon icon={["fas", "life-ring"]} />
              <span>Assistenza 24/7</span>
            </div>
          </div>
        </div>
      </div>

      <div className="container-benvenuto">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-12 col-md-6 col-sm-6">
              <h1 className="titolo">Benvenuto da You Know! Tour in barca </h1>
              <p className="sottotitolo">Facciamo le presentazioni...</p>
              <p>You Know! è la prima piattaforma online che offre ai viaggiatori la possibilità di prenotare e vivere i migliori tour ed esperienze in barca in tutta Europa. Tutto inizia a Sorrento nel 2011 dall'idea di Enrico Manzi, da sempre amante del mare che, con un unico tour in barca, desiderava mostrare ai viaggiatori un lato diverso dell'isola di Capri partendo da poche semplici premesse: tour via mare, piccoli gruppi a bordo, attenzione esclusiva al cliente, guide eccellenti ed esperienze uniche.
                Quella semplice formula ha funzionato così bene che ad oggi siamo cresciuti fino a diventare una delle realtà più specializzate nel campo delle escursioni via mare in tutta Europa, grazie ad una rete internazionale di partner selezionati, esperienze verificate e alle spalle, un team giovane e dinamico con un semplice obiettivo: regalare ai propri ospiti un'esperienza in barca indimenticabile, il più bel giorno della loro vacanza!
                Ad oggi, più di un milione di persone hanno usato You Know! per realizzare esperienze che hanno sempre sognato. E siamo solo all'inizio.
              </p>

            </div>
            <div className="col-12 col-md-6 col-sm-6 ">
              <img src="https://www.youknowboat.com/wp-content/themes/ykb2/images/staff-ykb.jpg" alt="escursioni" className="wow slideInUp img-ykb" data-wow-delay="0.5s" />
            </div>
          </div>
        </div>
      </div>


      <div className="container-servizi">
        <h2>Le escursioni in barca più amate</h2>
        <span className="sottotitolo">I NOSTRI BEST SELLER</span>

        <Glide
          ref={gliderRef}
          type="carousel"
          customSlideAnimation={{
            timeout: 200,
            classNames: 'fade',
          }}
          //peek={{
          //before: 500,
          //after: 500,
          //}}
          perView={5}
          slideClassName="slider__frame"
          focusAt="center"
        >
          <Fragment>
            <a href="https://www.youknowboat.com/escursione-barca-sorrento-capri-grotta-azzurra/"><img src="https://www.youknowboat.com/wp-content/uploads/2019/02/Capri-prime-Copertina-768x432.jpg" alt="escursioni" class="img-fluid" /></a>
            <div className="container-text">
              <p className="etichetta-servizi">TOUR IN BARCA DA SORRENTO</p>
              <a href="https://www.youknowboat.com/escursione-barca-sorrento-capri-grotta-azzurra/"><h4>Escursione in barca a Sorrento, Capri e Grotta Azzurra – Esperienza Prime con massimo 7 passeggeri</h4></a>
              <p className="prezzo"><span>da</span>€ 139</p>
              <div className="punteggi">
                <br />319 recensioni<br />
              </div>
            </div>
            <div className="btn-flex">
              <a href="https://www.youknowboat.com/escursione-barca-sorrento-capri-grotta-azzurra/" className="btn btn-primary">Scopri i dettagli</a>
            </div>
          </Fragment>
          <Fragment>
            <a href="https://www.youknowboat.com/capri-giro-dellisola/"><img src="https://www.youknowboat.com/wp-content/uploads/2018/01/capri-tour-by-boat-araund-island-from-capri-900x506.jpg" alt="escursioni" className="img-fluid" /></a>
            <div className="container-text">
              <p className="etichetta-servizi">TOUR IN BARCA DA CAPRI</p>
              <a href="https://www.youknowboat.com/capri-giro-dellisola/"><h4>Il meglio di Capri in 2 ore – Giro in barca intorno all’isola</h4></a>
              <p className="prezzo"><span>da</span>€ 59</p>
              <div className="punteggi">
                <br />689 recensioni<br />
              </div>
            </div>
            <div className="btn-flex">
              <a href="https://www.youknowboat.com/capri-giro-dellisola/" className="btn btn-primary">Scopri i dettagli</a>
            </div>
          </Fragment>
          <Fragment>
            <a href="https://www.youknowboat.com/capri-giro-dellisola/"><img src="https://www.youknowboat.com/wp-content/uploads/2019/01/Positano-Amalfi-boat-tour-from-rome-coast-amalfi-900x506.jpg" alt="escursioni" className="img-fluid" /></a>
            <div className="container-text">
              <p className="etichetta-servizi">TOUR IN BARCA DA ROMA</p>
              <a href="https://www.youknowboat.com/capri-giro-dellisola/"><h4>Escursione in barca in Costiera Amalfitana da Roma con treno ad alta velocità</h4></a>
              <p className="prezzo"><span>da</span>€ 274</p>
              <div className="punteggi">
                <br />104 recensioni<br />
              </div>
            </div>
            <div className="btn-flex">
              <a href="https://www.youknowboat.com/capri-giro-dellisola/" className="btn btn-primary">Scopri i dettagli</a>
            </div>
          </Fragment>
          <Fragment>
            <a href="https://www.youknowboat.com/capri-giro-dellisola/"><img src="https://www.youknowboat.com/wp-content/uploads/2019/01/Positano-Amalfi-boat-tour-from-rome-coast-amalfi-900x506.jpg" alt="escursioni" className="img-fluid" /></a>
            <div className="container-text">
              <p className="etichetta-servizi">TOUR IN BARCA DA ROMA</p>
              <a href="https://www.youknowboat.com/capri-giro-dellisola/"><h4>Escursione in barca in Costiera Amalfitana da Roma con treno ad alta velocità</h4></a>
              <p className="prezzo"><span>da</span>€ 274</p>
              <div className="punteggi">
                <br />104 recensioni<br />
              </div>
            </div>
            <div className="btn-flex">
              <a href="https://www.youknowboat.com/capri-giro-dellisola/" className="btn btn-primary">Scopri i dettagli</a>
            </div>
          </Fragment>
          <Fragment>
            <a href="https://www.youknowboat.com/capri-giro-dellisola/"><img src="https://www.youknowboat.com/wp-content/uploads/2019/01/Positano-Amalfi-boat-tour-from-rome-coast-amalfi-900x506.jpg" alt="escursioni" className="img-fluid" /></a>
            <div className="container-text">
              <p className="etichetta-servizi">TOUR IN BARCA DA ROMA</p>
              <a href="https://www.youknowboat.com/capri-giro-dellisola/"><h4>Escursione in barca in Costiera Amalfitana da Roma con treno ad alta velocità</h4></a>
              <p className="prezzo"><span>da</span>€ 274</p>
              <div className="punteggi">
                <br />104 recensioni<br />
              </div>
            </div>
            <div className="btn-flex">
              <a href="https://www.youknowboat.com/capri-giro-dellisola/" className="btn btn-primary">Scopri i dettagli</a>
            </div>
          </Fragment>


        </Glide>
        <button class="btn btn-primary" type="button" onClick={() => history.push('/tour-in-barca-da-sorrento')}>Tour in barca da Sorrento</button>
      </div>

      <div className="container-destinazione">
        <h3>Scegli la tua destinazione</h3>
        <section class="section cities-grid-container">
          <div class="container-flex-europa">
            <div class="flex-item-europa flex-item-italia">
              <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-in-italia">ITALIA</a>
              <img src="https://www.youknowboat.com/wp-content/themes/ykb2/images/tour-gruppo/group-tour-by-boat-italy.jpg" className="" alt=" tour di gruppo in italia" />
              <div className="container-descr-nazione">
                <p>13 destinazioni</p> <p>37 esperienze in barca</p> <p>1150 recensioni reali</p> <div className="punteggi">
                </div>
              </div>
            </div>
            <div className="flex-item-europa flex-item-grecia">
              <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-in-grecia">GRECIA</a>
              <img src="https://www.youknowboat.com/wp-content/themes/ykb2/images/tour-gruppo/group-tour-by-boat-greece.jpg" className="" alt=" tour di gruppo in grecia" />
              <div className="container-descr-nazione">
                <p>5 destinazioni</p> <p>13 esperienze in barca</p> <p>450 recensioni reali</p> <div className="punteggi">
                </div>
              </div>
            </div>
            <div className="flex-item-europa flex-item-croazia">
              <a href="https://www.youknowboat.com/escursioni-di-gruppo-in-barca-in-croazia">CROAZIA</a>
              <img src="https://www.youknowboat.com/wp-content/themes/ykb2/images/tour-gruppo/group-tour-by-boat-croazia.jpg" className="" alt=" tour di gruppo in croazia" />
              <div className="container-descr-nazione">
                <p>5 destinazioni</p> <p>14 esperienze in barca</p> <p>350 recensioni reali</p> <div className="punteggi">
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>



      <div className="container-tour-home">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-sm-12 col-12">
              <p class="titolo">Tour firmati You Know!</p>
              <p class="sottotitolo">Scopri tutte le nostre escursioni in barca da Sorrento</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="flex-container-home">
                <div class="flex-item-1">
                  <a href="https://www.youknowboat.com/tour-capri-in-barca-da-sorrento/">
                    <img src="https://www.youknowboat.com/wp-content/themes/ykb2/images/by-youknow.png" class="peel-seller" alt="best seller" />
                    <img src="https://www.youknowboat.com/wp-content/themes/ykb2/images/escursioni-capri-sorrento.jpg" alt="escursioni" class="img-fluid" /></a>
                  <div class="testo">
                    <p class="titolo">ESCURSIONI IN BARCA Capri e Sorrento</p>
                    <p class="desc">Scopri il fascino di Capri e delle bellezze di Sorrento
                      con un tour escursione in barca della durata di 8 ore.Ammirarai i faraglioni,
                      le grotte di Capri e farai il bagno nelle acque cristalline dell’isola più bella d’Italia.</p>
                    <a href="https://www.youknowboat.com/tour-capri-in-barca-da-sorrento/" class="btn btn-primary" role="button">
                      <i class="fa fa-binoculars" aria-hidden="true"></i>Scopri l’escursione</a>
                  </div>
                </div>
                <div class="flex-item-2">
                  <a href="https://www.youknowboat.com/escursione-in-barca-capri-da-sorrento-under-30">
                    <img src="https://www.youknowboat.com/wp-content/themes/ykb2/images/by-youknow.png" class="peel-seller" alt="best seller" />
                    <img src="https://www.youknowboat.com/wp-content/themes/ykb2/images/under-30-capri-tour.jpg" alt="escursioni" class="img-100" /></a>
                  <div class="testo">
                    <p class="titolo">Under 30' s Capri Fun Tour</p>
                    <p class="desc">Divertimento a tutta velocità col nuovissimo Under 30s Capri Fun Tour!
                      Scattanti gommoni per sfrecciare tra le onde e l'entusiasmante Banana Boat per incontenibili risate.</p>
                    <a href="https://www.youknowboat.com/escursione-in-barca-capri-da-sorrento-under-30" class="btn btn-primary" role="button">
                      <i class="fa fa-binoculars" aria-hidden="true"></i>Scopri l’escursione</a>
                  </div>
                </div>
                <div class="flex-item-2">
                  <a href="https://www.youknowboat.com/escursione-barca-positano-amalfi-da-sorrento/">
                    <img src="https://www.youknowboat.com/wp-content/themes/ykb2/images/by-youknow.png" class="peel-seller" alt="best seller" />
                    <img src="https://www.youknowboat.com/wp-content/themes/ykb2/images/escursioni-capri-sorrento-2.jpg" alt="escursioni" class="img-100" /></a>
                  <div class="testo">
                    <p class="titolo">Amalfi Coast tour da sorrento</p>
                    <p class="desc">Un paesaggio mozzafiato quello che collega le due perle della Costiera Amalfitana: Positano con l’incanto delle sue terrazze a picco sul mare e Amalfi, con lo spettacolo dei suoi colori.</p>
                    <a href="https://www.youknowboat.com/escursione-barca-positano-amalfi-da-sorrento/" class="btn btn-primary" role="button">
                      <i class="fa fa-binoculars" aria-hidden="true"></i>Scopri l’escursione</a>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="container-fluid container-green-echo-home">
        <div className="row">
          <div className="col-12 col-md-5 green-echo-home">
            <img src="https://www.youknowboat.com/wp-content/themes/ykb2/images/green/logo-ykb-green.svg" alt="you know green echo" className="img-logo-green-echo" />
            <h3>You Know!<br /><strong>Green Echo</strong></h3>
            <p>è il nostro impegno a generare un cambiamento positivo per le persone e per il nostro pianeta <br />
              è la nostra idea di azione per un futuro più sostenibile <br />
              è la nostra sfida di ricerca e innovazione<br />
              Siamo tutti agenti di cambiamento. E questa è la nostra voce, il nostro eco.
            </p>
            <div className="container-fluid container-timeline">
              <div className="row">
                <div className="container">

                  <picture>
                    <source media="(max-width: 799px)" srcset="https://www.youknowboat.com/wp-content/themes/ykb2/images/green/timeline-green-mob.png" className="img-fluid img-timeline" />
                    <source media="(min-width: 800px)" srcset="https://www.youknowboat.com/wp-content/themes/ykb2/images/timeline-home.png" />
                    <img src="elva-800w.jpg" alt="timeline ykb green echo" className="img-fluid img-timeline" />
                  </picture>
                </div>
              </div>
            </div>
            <br /><br />
            <a href="https://www.youknowboat.com/green-echo">Scopri di più su You Know!Green Echo e la Sustainability Timeline</a>
          </div>
          <div className="col-12 hidden-md">
            <img src="https://www.youknowboat.com/wp-content/themes/ykb2/images/bg-mare-greenecho-mod.jpg" alt="you know green echo" class="d-block d-sm-none" />
          </div>
        </div>
      </div>

      <div className="container-fluid container-cancel">
        <div className="row">
          <div className="container">
            <FontAwesomeIcon icon={["fas", "calendar-times"]} />

            <p className="h1">Cancellazione Gratuita</p>
            <p>Per la maggior parte delle esperienze,<br /> riceverai un rimborso completo se cancelli almeno 24 ore prima.</p>
          </div>
        </div>
      </div>

      

      <div class="container-testimonial">
        <div class="container">
          <div class="row">
            <div class="col-md-7 col-sm-7 col-12">

              <div class="container-commento slick-slide slick-current">
                <div class="testo">
                  <p class="titolo">Esperienza fantastica! - Giro in barca all'isola di Capri con nuotata, viste spettacolari e Limoncello</p>
                  <p class="data">
                    <img src="https://www.youknowboat.com/wp-content/themes/ykb-child/images/circle-trip-advisor.png" alt="" class="img-fluid" />
                    recensito a 26 settembre 2021
                    <p>
                      Esperienza fantastica. Luisa si è rivelata un’ottima guida e Luca un bravissimo capitano. Siamo partiti in barca da Sorrento, abbiamo fatto il bagno vicino alla grotta azzurra (non esplorabile causa mare mosso), poi siamo sbarcati a Capri, dove siamo rimasti quasi 5 ore, per permetterci di visitarla al meglio. Una volta tornati sulla barca, siamo passati in mezzo ai magnifici Faraglioni, abbiamo degustato un buon prosecco, un bicchiere di limoncello e un assaggio di torta caprese. Un po’ di musica e di spensieratezza hanno allietato la traversata in barca.
                      Consigliatissimo
                      Gabriela B. - Italia
                      <br />
                    </p>
                  </p>
                </div>

              </div>
            </div>

            <div class="col-md-5  col-sm-5 col-12 trip-advisor">
              <div class="wow slideInUp" data-wow-delay="1s">
                <a href="https://www.tripadvisor.it/Attraction_Review-g616172-d2536656-Reviews-You_Know_Boat_Excursions_Service-Piano_di_Sorrento_Province_of_Naples_Campania.html" rel="nofollow">
                  <img srcset="https://www.youknowboat.com/wp-content/themes/ykb2/images/trip-advisor.jpg,
             			https://www.youknowboat.com/wp-content/themes/ykb2/images/trip-advisor-x2.jpg 2x" src="https://www.youknowboat.com/wp-content/themes/ykb2/images/trip-advisor-x2.jpg" alt="tripadvisor certificato d'eccellenza" />
                </a>
                <p class="titolo">Certificato di Eccellenza 2021<br />
                  <span>98% di clienti soddisfatti</span></p>
                <a href="https://www.tripadvisor.it/Attraction_Review-g616172-d2536656-Reviews-You_Know_Boat_Excursions_Service-Piano_di_Sorrento_Province_of_Naples_Campania.html" rel="nofollow" class="btn btn-primary" role="button">Leggi le recensioni</a>
              </div>
            </div>


          </div>
        </div>
      </div>
      

      <Footer />
    </div>
  );
}

/* 

<div class="container">

        <div class="d-block">
          <button class="btn btn-primary" type='button' onClick={() => history.push('/vino')}>
            Vino
          </button>

          <button class="btn btn-primary" type='button' onClick={() => history.push('/en/wine')}>
            Wine
          </button>


          <button class="btn btn-primary" type='button' onClick={() => history.push('/category')}>
            Categoria
          </button>

          <button class="btn btn-primary" type='button' onClick={() => history.push('/en/category')}>
            Category En
          </button>

          <button class="btn btn-primary" type="button" onClick={() => history.push('/tour-in-barca-da-sorrento')}>Tour in barca da Sorrento</button>
        </div>
      </div>

 <Glide
              ref={gliderRef1}
              type="carousel"
              customSlideAnimation={{
                timeout: 200,
                classNames: 'fade',
              }}
              //peek={{
              //before: 500,
              //after: 500,
              //}}
              perView={5}
              slideClassName="slider__frame"
              focusAt="center"
            >


              <Fragment>
                <a href="https://www.youknowboat.com/capri-giro-dellisola/"><img src="https://www.youknowboat.com/wp-content/uploads/2019/01/Positano-Amalfi-boat-tour-from-rome-coast-amalfi-900x506.jpg" alt="escursioni" className="img-fluid" /></a>
                <div className="container-text">
                  <p className="etichetta-servizi">TOUR IN BARCA DA ROMA</p>
                  <a href="https://www.youknowboat.com/capri-giro-dellisola/"><h4>Escursione in barca in Costiera Amalfitana da Roma con treno ad alta velocità</h4></a>
                  <p className="prezzo"><span>da</span>€ 274</p>
                  <div className="punteggi">
                    <br />104 recensioni<br />
                  </div>
                </div>
                <div className="btn-flex">
                  <a href="https://www.youknowboat.com/capri-giro-dellisola/" className="btn btn-primary">Scopri i dettagli</a>
                </div>
              </Fragment>
              <Fragment>
                <a href="https://www.youknowboat.com/capri-giro-dellisola/"><img src="https://www.youknowboat.com/wp-content/uploads/2019/01/Positano-Amalfi-boat-tour-from-rome-coast-amalfi-900x506.jpg" alt="escursioni" className="img-fluid" /></a>
                <div className="container-text">
                  <p className="etichetta-servizi">TOUR IN BARCA DA NAP</p>
                  <a href="https://www.youknowboat.com/capri-giro-dellisola/"><h4>Escursione in barca in Costiera Amalfitana da Roma con treno ad alta velocità</h4></a>
                  <p className="prezzo"><span>da</span>€ 274</p>
                  <div className="punteggi">
                    <br />104 recensioni<br />
                  </div>
                </div>
                <div className="btn-flex">
                  <a href="https://www.youknowboat.com/capri-giro-dellisola/" className="btn btn-primary">Scopri i dettagli</a>
                </div>
              </Fragment>


            </Glide>
            */




export default withRouter(App);
